<template>
	<div class="watch-library-wrapp">
		<template v-if="vodInfo">
			<div class="video-wrapp">
				<VideoPlayer v-if="!embedded" :options="videoOptions"/>
				<div v-else v-html="embedded"></div>
			</div>
			<div class="video-description-wrapper">
				<span class="video-title">{{vodInfo.title}}</span>
				<span v-if="categoryTitle" class="video-category">{{categoryTitle}}</span>
				<span v-if="host" class="video-host">Host: {{host}}</span>
				<span v-if="formattedDate" class="video-date">{{formattedDate}}</span>
				<span class="video-description">{{vodInfo.description}}</span>
			</div>
		</template>
		<template v-else>
			<div>
				<loader/>
			</div>
		</template>
	</div>
</template>

<script>
  import {ASSETS_TYPE} from "../../const";
  import VideoPlayer from "../../components/video/VideoPlayer";
  import {mapActions} from "vuex";
  import Loader from "../../components/Loader";

  export default {
    name: "WatchLibraryScreen",
    data() {
      return {
        vodInfo: null,
        id: this.$route.params.id,
        categories: null
      }
    },
    components: {Loader, VideoPlayer},
    methods: {
      ...mapActions(['GET_VOD_ITEM', 'GET_VOD_CATEGORIES'])
    },
    computed: {
      embedded() {
        return this.vodInfo?.assets.find(item => item.type == ASSETS_TYPE.embed)?.path
      },
      videoPath() {
        return this.vodInfo?.assets?.find(asset => asset.type === ASSETS_TYPE.video)?.path
      },
      videoOptions() {
        return {
          autostart: true,
          file: this.videoPath
        }
      },
      categoryTitle(){
        return this.categories?.find(({id}) => Number(id) === Number(this.vodInfo?.vod_media_category_id))?.title
      },
      host(){
        return this.vodInfo?.metadata?.host
      },
      formattedDate(){
        const date = new Date(this.vodInfo?.date)
        const d = date.getDate()
        const m = date.toLocaleDateString(this.$i18n.locale(), { month: 'long'})
        const y = date.getFullYear()
        return `${d} ${m} ${y}`
      }
    },
    mounted() {
      const params = {
        id: this.id
      }
      this.GET_VOD_ITEM(params)
          .then(({data}) => {
            this.vodInfo = data;
          })
          .catch(_ => (this.vodInfo = null))
      this.GET_VOD_CATEGORIES()
        .then(({data}) => this.categories = data)
        .catch(_ => (this.categories = null))
    }
  }
</script>

<style scoped lang="scss">
	@import "src/assets/css/mixins";
	@import "src/assets/css/colors";

	.watch-library-wrapp {
		--content-width: 80vw;
		@include media-max(lg) {
			--content-width: calc(100vw - 2rem)
		}

		margin-top: 1.5rem;
	}

	.video-wrapp {
		max-width: var(--content-width);
		margin: 0 auto;
	}

	.video-description-wrapper {
		max-width: var(--content-width);
		display: flex;
		flex-direction: column;
		text-align: left;
		margin: 2rem auto;
		color: $text-tertiary-middle;
	}

	.video-title {
		font-size: 1.5rem;
		color: $text-primary;
	}
	.video-description{
		margin-top: 1rem;
	}
</style>